import React from 'react';
import { StyledAlignContent } from './styled';
import { PotentialThemeColorType } from '~/theme/model/themeModel';

export interface AlignContentProps {
    /**
     * Horizontal align content
     */
    alignment?: 'left' | 'center' | 'right';

    /**
     * Horizontal align content
     */
    position?: 'top' | 'center' | 'bottom';

    textColor?: PotentialThemeColorType;
    /**
     * Set a maxWidth for the content
     */
    maxWidth?: number | string;

    children: React.ReactNode;
    className?: string;
    disablePointerEvents?: boolean;
}

export const AlignContent = ({
    alignment = 'left',
    position = 'top',
    children,
    className,
    ...rest
}: AlignContentProps) => {
    return (
        <StyledAlignContent
            className={className}
            alignment={alignment}
            position={position}
            {...rest}
        >
            {children}
        </StyledAlignContent>
    );
};
